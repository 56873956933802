
#work {
    margin: 0 auto;
    min-height: calc(100vh - 4rem);
    width: 100vw;
    max-width: 60rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 1rem;
}

.workTitle {
    margin: 1rem 0;
    font-size: 3rem;
}

.worksDesc {
    font-weight: 300;
    font-size: 1rem;
    max-width: 45rem;
}

.workImgs {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100vw;
    max-width: 65rem;
}

.worksHeader{
    color: green;
}

.worksImg {
    object-fit: cover;
    height: 20rem;
    margin: 0.5rem;
    border-radius: 30px;
    max-width: 95%;
}

.workBtn {
    margin: 3rem 0;
    padding: 0.5rem 2.5rem;
    border: none;
    background: white;
    border-radius: 2rem;
    font-size: 1rem;
}

.portfolio-description{
    color: white;
    font-weight: 300;
    max-width: 87%;
    margin-left: 3rem;
}

@media screen and (max-width:585px) {
    .worksImg {
        height: 50vw;
    }

    .portfolio-description{
        max-width: 95%;
        font-weight: 300;
        font-size: 1rem;
        margin-left: 1rem;
    }
}


