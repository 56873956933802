
.navbar {
    background: rgb(30, 30, 30);
    height: 5rem;
    width: 100vw;
    max-width: 75rem;
    margin: 0 auto;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 3;

}

.logo {
    object-fit: cover;
    height: 3rem;
    /* Maybe come back to this */
    width: auto;
}

.desktopMenuListItem {
    margin: 1rem;
    cursor: pointer;
}

.contactMeBtn {
    background: white;
    color: black;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    border-radius: 2rem;
}

.contactMeBtn:hover {
    cursor: pointer;
}

.desktopMenuListItem:hover {
    color: green;
    padding-bottom: 0.5rem;
    border-bottom: 3px solid green;
}

.contactMeImg {
    object-fit: cover;
    height: 1rem;
    width: 1rem;
    margin: 1rem;
}

.active {
    color: green;
    padding-bottom: 0.5rem;
    border-bottom: 3px solid green;
}
